import React from "react"
import Layout from "../components/layout";
import { graphql, Link, useStaticQuery } from "gatsby"

// @ts-expect-error
import voidBg from "../images/emile-seguin-R9OueKOtGGU-unsplash.jpg"
import { Stack, Heading, Text } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/button";
import { useColorModeValue as mode } from "@chakra-ui/color-mode";

const NotFoundPage = () => {
  const color = mode("gray.800", "gray.800")

  const backgroundPath = useStaticQuery(graphql`
    query {
      contentfulAsset(title: { eq:"You're in the void"}) {
        file {
          url
        }
      }
    }
  `)
  
  return (
    <Layout title="You're in the void 🙁" icon="🌑" showHeader={false}>
      <Stack as="section"
        background={`url(${backgroundPath.contentfulAsset.file.url}) rgba(255, 234, 202, 1)`}
        backgroundBlendMode="multiply"
        minHeight="100vh"
        backgroundPosition="50% 70%"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        padding="96px"
        textAlign="center"
        color={color}
      >
        <Heading
          fontSize="clamp(24px, 45px + 4vw, 100px)"
          lineHeight="1"
        >
          Uh, oh
        </Heading>
        <Text
          fontSize="clamp(14px, 14px + 1vw, 35px)"
          fontStyle="italic"
          
        >
          You're in the void
        </Text>

        <Link to="/">
          <Button
            color="white"
            marginTop={8}
            display="inline-flex"
            width="auto"
            minWidth={8}
            borderRadius="3xl"
            paddingLeft={{base: 4, md: 8, lg: 8}}
            paddingRight={{base: 4, md: 8, lg: 8}}
            paddingTop={0}
            paddingBottom={0}
            background="blackAlpha.900"
            _hover={{bg: "blackAlpha.800"}}
            _active={{bg: "blackAlpha.800"}}
          >
              Take me home
          </Button>
        </Link>
      </Stack>
    </Layout>
  )
}

export default NotFoundPage
